import type { ReactNode } from "react";
import { useEffect } from "react";
import { useFocusable } from "@noriginmedia/norigin-spatial-navigation";

import { Button, Spinner } from "@/components";
import { button } from "@/config/size";

import { useListScrolling } from "../utils/use-list-scrolling";
import * as styles from "./channel-groups.css";

type ChannelGroupsButtonProps = CommonProps & {
  text: string;
  onArrowPress: (direction: string) => boolean;
  onEnterPress: () => void;
  active?: boolean;
  focusKey: string;
  onScreen: boolean;
  scrollRef: React.RefObject<HTMLElement | null>;
};

export function ChannelGroupsButton({
  text,
  focusKey,
  onArrowPress,
  active,
  onEnterPress,
  onScreen,
  scrollRef,
  "data-testid": dataTestId,
}: ChannelGroupsButtonProps): ReactNode {
  const { ref, focused, focusSelf } = useFocusable({
    onEnterPress,
    focusKey,
    onArrowPress,
  });
  const element = ref.current as HTMLElement | undefined;

  const scroll = useListScrolling(
    element,
    scrollRef,
    button.default.height,
    13,
  );

  useEffect(() => {
    if (!focused || !element || !scroll) return;
    scroll();
  }, [element, focused, scroll]);

  if (!onScreen)
    return (
      <div ref={ref} className={styles.spinner}>
        <Spinner data-testid={dataTestId} />
      </div>
    );

  return (
    <Button
      ref={ref}
      active={active}
      data-focused={focused}
      data-testid={dataTestId}
      focused={focused}
      text={text}
      textAlign="left"
      variant="ghost"
      block
      onMouseClick={onEnterPress}
      onMouseEnter={focusSelf}
    />
  );
}
