import type { ReactElement } from "react";
import { useCallback, useEffect, useMemo } from "react";
import { useFocusable } from "@noriginmedia/norigin-spatial-navigation";
import clsx from "clsx";
import { useAtomValue } from "jotai";

import { isCursorVisibleAtom, useKeyboardNavigation } from "@sunrise/bigscreen";
import type { Nullable } from "@sunrise/utils";
import { useSeekbar, useTogglePlayPause } from "@sunrise/yallo-player-controls";

import { type ProgramBreak, ProgressBar } from "@/components";
import { SCREEN_WIDTH_IN_PX } from "@/core";
import * as styles from "@/features/player-controls/player-seekbar.css";
import { SEEKBAR_MARGIN } from "@/features/player-controls/player-seekbar.css";
import { Thumbnail } from "@/features/player-controls/thumbnail";
import type { OnBlurRequestFunction } from "@/features/settings/types";
import { baseGap } from "@/styles/theme.css";
import {
  isArrowDownKey,
  isArrowLeftKey,
  isArrowRightKey,
} from "@/utils/navigation";

type PlayerSeekbarProps = {
  focusKey?: string;
  onBlurRequest?: OnBlurRequestFunction;
} & CommonProps;

const THUMBNAIL_WIDTH = 300;

export function PlayerSeekbar({
  focusKey = "player-seekbar",
  onBlurRequest,
  "data-testid": dataTestId = "player-seekbar",
}: PlayerSeekbarProps): ReactElement {
  const {
    liveProgress,
    replayProgress,
    currentTime,
    durationLeft,
    progress,
    progressWithoutSeek,
    seek,
    breaks,
    isSeeking,
  } = useSeekbar();

  const { toggle } = useTogglePlayPause();
  const isCursorVisible = useAtomValue(isCursorVisibleAtom);
  const isSeekingWithCursor = isCursorVisible && isSeeking;

  const mappedBreaks: ProgramBreak[] = useMemo(() => {
    return (
      breaks?.map((br) => {
        if (br.kind === "recording") {
          return {
            isRecording: true,
            start: br.startsAtPercentage,
          };
        }

        return {
          start: br.startsAtPercentage,
          length: br.lengthInPercentage,
          isRecording: false,
        };
      }) ?? []
    );
  }, [breaks]);

  const { reset } = seek;

  useKeyboardNavigation({
    onBack: useCallback(() => seek.reset(), [seek]),
    isEnabled: isSeekingWithCursor,
  });

  const { ref, focused, focusSelf } = useFocusable({
    focusKey,
    onArrowPress: (direction) => {
      if (isArrowDownKey(direction)) {
        onBlurRequest?.(direction);
        return false;
      }

      if (isArrowLeftKey(direction)) {
        void seek.backward();
        return false;
      }

      if (isArrowRightKey(direction)) {
        void seek.forward();
        return false;
      }

      return true;
    },
    onEnterPress: () => {
      if (isSeeking) {
        void seek.confirm();
      } else {
        toggle();
      }
    },
  });

  useEffect(() => {
    if (!focused) {
      reset();
    }
  }, [focused, reset]);

  const leftBound = THUMBNAIL_WIDTH / 2;
  const rightBound =
    SCREEN_WIDTH_IN_PX - 2 * SEEKBAR_MARGIN - THUMBNAIL_WIDTH / 2;
  const thumbnailPosition =
    ((SCREEN_WIDTH_IN_PX - 2 * SEEKBAR_MARGIN) * (progress ?? 0)) / 100;

  const leftPosition = Math.round(
    Math.max(leftBound, Math.min(rightBound, thumbnailPosition)),
  );

  // arrow behavior on the thumbnail bound position should be that the arrow is aligned with knob
  const getArrowBoundPosition = (): Nullable<number> => {
    if (thumbnailPosition > rightBound) {
      return Math.min(
        -(
          SCREEN_WIDTH_IN_PX -
          2 * SEEKBAR_MARGIN -
          THUMBNAIL_WIDTH -
          thumbnailPosition +
          baseGap * 1.5
        ),
        THUMBNAIL_WIDTH - baseGap * 2,
      );
    }
    if (thumbnailPosition < leftBound) {
      return Math.max(thumbnailPosition - baseGap * 1.25, 1);
    }

    return null;
  };

  return (
    <div className={styles.container}>
      <div
        className={styles.thumbnail}
        style={{
          left: leftPosition,
        }}
      >
        <Thumbnail
          arrowPosition={getArrowBoundPosition()}
          width={THUMBNAIL_WIDTH}
        />
      </div>
      <div
        className={clsx(styles.knobTimeContainer, styles.timeContainer)}
        style={{
          left: `${progress}%`,
        }}
      >
        <div className={styles.time} data-testid={`${dataTestId}.currentTime`}>
          {currentTime}
        </div>
      </div>
      <div className={styles.barContainer}>
        <ProgressBar
          breaks={mappedBreaks}
          data-testid={`${dataTestId}.progressBar`}
          height={12}
          liveProgress={liveProgress}
          radius={6}
          replayProgress={
            isSeekingWithCursor ? progressWithoutSeek : replayProgress
          }
          onEnter={() => seek.confirm()}
          onHover={(percentage) => seek.toPercentage(percentage)}
        />

        <div
          className={styles.knobTimeContainer}
          data-progress={progress}
          data-testid={`${dataTestId}.knob`}
          style={{
            left: `${isSeekingWithCursor ? progressWithoutSeek : (progress ?? 0)}%`,
          }}
        >
          <div
            ref={ref}
            className={clsx([styles.knob, focused && styles.knobFocused])}
            data-focused={focused}
            onClick={focusSelf}
          />
        </div>
      </div>
      <div
        className={styles.duration}
        data-testid={`${dataTestId}.durationLeft`}
      >
        -{durationLeft}
      </div>
    </div>
  );
}
