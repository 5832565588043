import {
  isArrowDownKey,
  isArrowLeftKey,
  isArrowRightKey,
  isArrowUpKey,
} from "./navigation";

export function listNavigationArrowPress(
  length: number,
  columnCount: number,
  currentIndex: number,
  direction: string,
  options?: {
    onLeftBoundary?: () => void;
    onTopBoundary?: (columnIndex: number) => void;
  },
): number | undefined {
  if (isArrowLeftKey(direction)) {
    const isFirstInRow = currentIndex % columnCount === 0;

    if (isFirstInRow && options?.onLeftBoundary) {
      options.onLeftBoundary();
    } else {
      return currentIndex - 1;
    }
  } else if (isArrowRightKey(direction)) {
    const isLastInRow = currentIndex % columnCount === columnCount - 1;
    if (!isLastInRow) {
      return currentIndex + 1;
    }
  } else if (isArrowDownKey(direction)) {
    const rowIdx = Math.floor(currentIndex / columnCount);
    const isLastFullRow = rowIdx === Math.floor(length / columnCount) - 1;

    if (!isLastFullRow) {
      return currentIndex + columnCount;
    } else {
      const next = currentIndex + columnCount;
      if (next < length) {
        return next;
      }

      return length - 1;
    }
  } else if (isArrowUpKey(direction)) {
    const rowIdx = Math.floor(currentIndex / columnCount);
    const isFirstRow = rowIdx === 0;
    const columnIdx = currentIndex % columnCount;

    if (isFirstRow) {
      if (options?.onTopBoundary) {
        options.onTopBoundary(columnIdx);
      }
    } else {
      return currentIndex - columnCount;
    }
  }
}
