import { type ReactNode, useCallback, useEffect } from "react";
import {
  doesFocusableExist,
  setFocus,
} from "@noriginmedia/norigin-spatial-navigation";
import { useAtomValue } from "jotai";
import { useAtomCallback } from "jotai/utils";

import { useKeyboardNavigation } from "@sunrise/bigscreen";
import {
  actionDialogClose,
  dialogAtom,
  selectCurrentlyOpenedDialog,
} from "@sunrise/dialogs";

import { Dialog } from "@/components";
import { globalFocusKey } from "@/config/focus-key";
import { ActionsDialog } from "@/features/dialogs/actions-dialog/actions-dialog";
import { DialogContainer } from "@/features/dialogs/generic/dialog-container";
import { ListDialog } from "@/features/dialogs/list-dialog/list-dialog";
import { RetryDialog } from "@/features/dialogs/retry-dialog/retry-dialog";
import type { DialogOptions } from "@/features/dialogs/types";
import { useCloseApp } from "@/features/routing/use-close-app";

import { SideBarActionsDialog } from "../side-bar-actions-dialog/side-bar-actions-dialog";

const TEST_ID = "dialog";

/**
 * This dialog will check the type of the DialogAtom and render the necessary contents inside.
 *
 * For now we support 2 dialog types here:
 * - ActionsDialog
 * - RetryDialog
 *
 * The goal is to have all dialogs work in a similar way, so that we can easily add new dialogs and have shared generic logic for dialogs.
 */
export function GenericDialog(): ReactNode {
  const dialog = useAtomValue(selectCurrentlyOpenedDialog);

  const doCloseModalId = useAtomCallback(
    useCallback((_, set, id: string) => {
      set(dialogAtom, actionDialogClose({ id }));
    }, []),
  );

  // TODO: In the end, this needs to go away. Pages themselves should know when to re-pull focus to themselves.
  //       When the dialog closes ... .
  const doClose = useCallback(() => {
    if (!dialog) {
      return;
    }

    doCloseModalId(dialog.id);
  }, [dialog, doCloseModalId]);

  // TODO: In the end, this needs to go away. Pages themselves should know when to re-pull focus to themselves.
  //       When the dialog closes ... .
  useEffect(() => {
    return () => {
      if (!dialog) {
        return;
      }

      // BUG: Because of the loading of the translations, this gets run. Which then re-opens the dialog.
      // Since another dialog is opening, we don't want to pull focus back to the last focus key.
      // In order to fix it we really need all pages to actively pull focus themselves again. Instead of pushing around the focus like this.
      if (doesFocusableExist(dialog.lastFocusKey)) {
        setFocus(dialog.lastFocusKey);
        return;
      }

      if (doesFocusableExist(globalFocusKey.activePage)) {
        setFocus(globalFocusKey.activePage);
      }
    };
  }, [dialog]);

  const backBehaviour = dialog?.backBehaviour;

  // close modal logic
  useKeyboardNavigation({
    onBack: doClose,
    isEnabled: !backBehaviour || backBehaviour === "close-modal",
  });

  // kill app logic.
  useCloseApp({
    isEnabled: backBehaviour === "kill-app",
    onClose: (confirm) => confirm(),
  });

  // TODO: Perhaps correct it so it injects the type into the ID as well.
  const testId = TEST_ID;
  const content = dialog
    ? getContent({ dialog, "data-testid": testId, doClose })
    : null;

  // List contains the Modal itself because it has custom styling.
  if (dialog?.type === "list") {
    return content;
  }

  // non-modal dialog on the side of the page
  if (dialog?.type === "actions" && dialog?.onTheSide) {
    return <SideBarActionsDialog {...dialog} doClose={doClose} />;
  }

  return (
    <Dialog data-testid={testId} open={!!content}>
      {dialog ? (
        <DialogContainer data-testid={`${testId}.content`} dialog={dialog}>
          {content}
        </DialogContainer>
      ) : null}
    </Dialog>
  );
}

function getContent(options: DialogOptions): ReactNode {
  switch (options.dialog.type) {
    case "actions":
      return <ActionsDialog {...options} dialog={options.dialog} />;
    case "retry":
      return <RetryDialog {...options} dialog={options.dialog} />;
    case "list":
      return <ListDialog {...options} dialog={options.dialog} />;
    default:
      return null;
  }
}
