import { useCallback } from "react";
import { useAtomValue, useSetAtom } from "jotai";
import { useAtomCallback } from "jotai/utils";

import { useKeyboardNavigation } from "@sunrise/bigscreen";
import { selectIsDialogOpen } from "@sunrise/dialogs";
import { actionLocationNavigate, locationAtom } from "@sunrise/location";
import { playerCurrentEpgItemAtom } from "@sunrise/player";
import { useTranslator } from "@sunrise/translator";
import {
  useSubtitleAudioSettings,
  useTogglePlayPause,
} from "@sunrise/yallo-player-controls";

import { route } from "@/config/route";

export function useTvScreenRemoteControls(): void {
  const isDialogOpen = useAtomValue(selectIsDialogOpen);
  const dispatchLocation = useSetAtom(locationAtom);
  const t = useTranslator();

  const { play, pause, toggle } = useTogglePlayPause();
  const { open: openSubtitleAudioSettings } = useSubtitleAudioSettings();

  useKeyboardNavigation({
    isEnabled: !isDialogOpen,
    onMediaPlay: play,
    onMediaPause: pause,
    onMediaPlayPause: toggle,
    onInfo: useAtomCallback(
      useCallback(
        async (get) => {
          const epgEntry = await get(playerCurrentEpgItemAtom);
          if (!epgEntry) return;

          dispatchLocation(
            actionLocationNavigate(
              route.details.root({
                epgId: epgEntry.id,
                assetId: epgEntry.asset.id,
              }),
            ),
          );
        },
        [dispatchLocation],
      ),
    ),
    onCaption: () => openSubtitleAudioSettings(t),
  });
}
