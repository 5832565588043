import type { ReactNode, Ref } from "react";
import { forwardRef } from "react";

import type { NavigationId } from "@sunrise/bigscreen";

import type { IconProps } from "@/components/icon";

import { Button } from "../button";

type MenuButtonProps = CommonProps & {
  navId: NavigationId;
  text: string;
  focused: boolean;
  active: boolean;
  icon: IconProps["name"];
  iconSize?: IconProps["size"];
  disabled?: boolean;
  hideText?: boolean;
};

export const MenuButton = forwardRef<HTMLButtonElement, MenuButtonProps>(
  function MenuButton(
    {
      text,
      icon,
      iconSize,
      focused,
      active,
      disabled,
      navId,
      "data-testid": dataTestId = "menuButton",
      hideText,
      className,
    }: MenuButtonProps,
    ref: Ref<HTMLButtonElement>,
  ): ReactNode {
    return (
      <Button
        ref={ref}
        active={active}
        className={className}
        data-testid={`${dataTestId}`}
        disabled={disabled}
        focused={focused}
        hideText={hideText}
        iconLeft={icon}
        iconSize={iconSize}
        navId={navId}
        text={text}
        textAlign="left"
        typography={{ size: "h6", weight: "bold" }}
        variant="secondary"
        block
        short
      />
    );
  },
);
