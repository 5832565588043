import type { ReactElement } from "react";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import {
  setFocus,
  useFocusable,
} from "@noriginmedia/norigin-spatial-navigation";
import { useAtomValue, useSetAtom } from "jotai";

import { MouseNavigationContext, type NavigationId } from "@sunrise/bigscreen";
import { debouncedQueryAtoms } from "@sunrise/search";
import {
  clearSearchHistoryButtonAtom,
  searchHistoryItemsAtom,
} from "@sunrise/search-history";
import { useTranslator } from "@sunrise/translator";

import type { ButtonProps } from "@/components";
import { Button } from "@/components";
import { isArrowLeftKey, isArrowRightKey } from "@/utils/navigation";
import { useScrollOnFocus } from "@/utils/use-scroll-on-focus";

import { button, container, innerContainer } from "./search-history.css";

export function SearchHistory({
  focusKey,
  onHide,
  exitLeft,
}: {
  focusKey: string;
  onHide: () => void;
  exitLeft: () => void;
}) {
  const items = useAtomValue(searchHistoryItemsAtom);
  if (items.length === 0) {
    return null;
  }

  return (
    <SearchHistoryInner
      exitLeft={exitLeft}
      focusKey={focusKey}
      items={items}
      onHide={onHide}
    />
  );
}

/**
 * Shows the items when any are present.
 * Will never pull focus to itself.
 */
function SearchHistoryInner({
  focusKey,
  onHide,
  exitLeft,
  items,
}: {
  focusKey: string;
  onHide: () => void;
  exitLeft: () => void;
  items: string[];
}) {
  const clearButton = useAtomValue(clearSearchHistoryButtonAtom);
  const setSearch = useSetAtom(debouncedQueryAtoms.currentValueAtom);
  const t = useTranslator();

  // Have a reference somewhere of which item is focused.
  const [activeText, setActiveText] = useState<string>();
  const isClearButtonFocused = !activeText;

  const onEnterPress = useCallback(() => {
    if (clearButton.visible && isClearButtonFocused) {
      setActiveText(undefined);
      clearButton.action();
      onHide();
      return false;
    }

    if (activeText) {
      setSearch(activeText);
      return false;
    }

    return true;
  }, [activeText, clearButton, onHide, setSearch, isClearButtonFocused]);

  const focus = useFocusable({
    focusKey,
    focusable: items.length > 0,
    onArrowPress(direction) {
      if (isArrowRightKey(direction)) {
        if (isClearButtonFocused) {
          return false;
        }

        setActiveText((prev) => {
          const item = items.indexOf(prev ?? "");
          return items[item + 1];
        });
        return false;
      }

      if (isArrowLeftKey(direction)) {
        if (isClearButtonFocused) {
          setActiveText(items[items.length - 1]);
          return false;
        }

        if (activeText === items[0]) {
          exitLeft();
          return false;
        }

        setActiveText((prev) => {
          const item = items.indexOf(prev ?? "");
          return items[item === -1 ? 0 : item - 1];
        });
        return false;
      }

      return true;
    },
    onEnterPress,
  });

  const focusElement = useCallback(
    (text: string | undefined) => {
      setFocus(focusKey);
      setActiveText(text);
    },
    [focusKey],
  );

  const outerRef = useRef<HTMLDivElement | null>(null);
  useEffect(() => {
    // Select the first one whenever the items change.
    setActiveText(items[0]);

    // Scroll into view as well.
    outerRef.current?.scrollTo(0, 0);
  }, [items, outerRef]);

  // Should scroll into view the search history itself when it regains focus.
  useScrollOnFocus({
    enabled: true,
    focused: focus.focused,
    ref: outerRef,
  });
  const navigation = useMemo(
    () => ({
      focusElement: (arg: NavigationId) => focusElement(arg as string),
      enterElement: onEnterPress,
    }),
    [focusElement, onEnterPress],
  );

  if (items.length === 0) {
    return null;
  }

  return (
    <div ref={outerRef} className={container} data-testid="search-history">
      <MouseNavigationContext.Provider value={navigation}>
        <div ref={focus.ref} className={innerContainer}>
          {items.map((item) => (
            <CarouselButton
              key={item}
              className={button}
              data-testid={`search-history-item-${item}`}
              focused={focus.focused && item === activeText}
              iconLeft="search-small"
              navId={item}
              shouldScroll={item === activeText}
              text={item}
              variant="light"
            />
          ))}
          {clearButton.visible && (
            <CarouselButton
              className={button}
              focused={focus.focused && isClearButtonFocused}
              navId={undefined}
              shouldScroll={focus.focused && isClearButtonFocused}
              text={t("search_history_clear")}
            />
          )}
        </div>
      </MouseNavigationContext.Provider>
    </div>
  );
}

function CarouselButton({
  shouldScroll,
  ...rest
}: ButtonProps & {
  shouldScroll: boolean;
}): ReactElement {
  const ref = useRef<HTMLButtonElement | null>(null);

  useScrollOnFocus({
    enabled: true,
    focused: shouldScroll,
    ref,
  });

  return <Button ref={ref} {...rest} />;
}
