import { atom } from "jotai";

import { tizenProductInfoAtom } from "./tizen-product-info.atom";
/**
 * This is an unstructured object that contains developer information about the device.
 */
export const tizenDeveloperDeviceInfoAtom = atom<
  Promise<Record<string, unknown>>
>(async (get) => {
  const { data: info } = await get(tizenProductInfoAtom);
  if (!info) {
    return {};
  }
  return {
    DUID: info.duid,
    Model: info.model,
    "Model Code": info.modelCode,
    "Real Model": info.realModel,
    Firmware: info.firmware,
  };
});
