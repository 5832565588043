import type { ReactNode } from "react";
import clsx from "clsx";
import { useAtomValue } from "jotai";

import { useKeyboardNavigation } from "@sunrise/bigscreen";
import { selectIsDialogOpen } from "@sunrise/dialogs";

import { channelList } from "@/config/size";
import { allowNumericZappingFlagAtom } from "@/modules/zapping/allow-numeric-zapping-flag.atom";
import { numericZappingAtom } from "@/modules/zapping/numeric-zapping.atom";
import { baseGap } from "@/styles/theme.css";
import { typography } from "@/styles/typography.css";

import {
  isChannelGroupsExpandedAtom,
  isChannelListExpandedAtom,
} from "../channel-list";
import { useNumericZapping } from "./hooks/use-numeric-zapping";
import * as styles from "./numeric-zapping.css";

export function NumericZapping({
  "data-testid": dataTestId = "numeric-zapping",
}: CommonProps): ReactNode {
  const { isVisible, channelNumber, zappingError, waitingForInput } =
    useAtomValue(numericZappingAtom);

  const isChannelListVisible = useAtomValue(isChannelListExpandedAtom);
  const isChannelGroupsVisible = useAtomValue(isChannelGroupsExpandedAtom);

  const isNumericZappingEnabled = useAtomValue(allowNumericZappingFlagAtom);
  const isDialogOpen = useAtomValue(selectIsDialogOpen);

  const { addNumber } = useNumericZapping();

  useKeyboardNavigation({
    isEnabled: !isDialogOpen && isNumericZappingEnabled,
    onNumber: addNumber,
  });

  if (!isVisible) return null;

  const numericZappingRightPosition = isChannelListVisible
    ? channelList.channels.width
    : isChannelGroupsVisible
      ? channelList.channels.width + channelList.channelGroups.width
      : 0;

  return (
    <div
      className={clsx([
        styles.root,
        typography.h1.bold,
        zappingError && styles.error,
      ])}
      data-testid={dataTestId}
      style={{ right: numericZappingRightPosition + baseGap * 12 }}
    >
      <p data-testid={`${dataTestId}.channel-number`}>{channelNumber}</p>
      {waitingForInput && channelNumber && !zappingError && (
        <p
          className={styles.debouncer}
          data-testid={`${dataTestId}.debouncer`}
        />
      )}
    </div>
  );
}
