import { type ReactNode, useMemo } from "react";
import { useFocusable } from "@noriginmedia/norigin-spatial-navigation";
import { useAtomValue } from "jotai";
import { atomWithDefault } from "jotai/utils";

import type { ChannelListItem } from "@sunrise/backend-types";
import { MouseNavigationContext } from "@sunrise/bigscreen";
import { simplifiedChannelInfoAtom } from "@sunrise/yallo-channel";
import { usePlayChannel } from "@sunrise/yallo-common-player-manager";

import { ChannelItem as ComponentsChannelItem } from "@/components";
import { ChannelLockIndicator } from "@/features/channel/channel-lock-indicator";

import { channelListItem } from "./channel-list.css";

const epgDefault = atomWithDefault(() => ({
  currentEpg: { start: "", title: "" },
  nextEpg: { start: "", title: "" },
  progress: 0,
}));

// NOTE: Would prefer to share this somewhere w/ the styling.
//       Or maybe poll the DOM to determine the heights.
// TODO: move to sizes file.
export const CHANNEL_ITEM_HEIGHT_IN_PX = 184;

type ChannelItemProps = CommonProps & {
  item: ChannelListItem;
  onLeft: () => void;
  onRight: () => void;
  onUp: () => void;
  onDown: () => void;
  onScreen: boolean;
  focusKey: string;
};

/**
 * This component combines the channel info and the current / next epg info
 * as well as the progress indicator and apsses that to the ChannelItem.
 *
 * It handles all the focus and scroll handling and just delegates
 * the visual rendering to the ChannelItem form the components library.
 *
 * When the component is not on screen it will not load any data.
 */
export function ChannelItem({
  item,
  onLeft,
  onRight,
  onUp,
  onDown,
  focusKey,
  /**
   * Disables the interactive bit of the component so it consumes less memory.
   */
  onScreen,
  style,
  "data-testid": dataTestid,
}: ChannelItemProps): ReactNode {
  const epgData = useAtomValue(
    onScreen ? simplifiedChannelInfoAtom(item.id) : epgDefault,
  );

  const { play, isActive } = usePlayChannel({
    channelId: item.id,
  });

  const { ref, focused, focusSelf } = useFocusable({
    onArrowPress: (direction) => {
      if (direction === "left") {
        onLeft();
        return false;
      }

      if (direction === "right") {
        onRight();
        return false;
      }

      if (direction === "up") {
        onUp();
        return false;
      }

      if (direction === "down") {
        onDown();
        return false;
      }

      return true;
    },
    onEnterPress: play,
    focusKey,
  });

  const navigation = useMemo(
    () => ({
      focusElement: () => focusSelf(),
      enterElement: () => play(),
    }),
    [play, focusSelf],
  );

  if (!epgData) return null;

  return (
    <MouseNavigationContext.Provider value={navigation}>
      <ComponentsChannelItem
        ref={ref}
        active={isActive}
        channelLogo={item.channelLogo}
        channelNumber={item.channelNumber}
        className={channelListItem}
        currentEpg={epgData.currentEpg}
        data-focused={focused}
        data-testid={dataTestid}
        focused={focused}
        id={item.id}
        skeleton={!onScreen}
        style={style}
        nextEpg={epgData.nextEpg}
        // TODO: validate this default value
        liveProgress={epgData.progress ?? 0}
      >
        <ChannelLockIndicator
          channelId={item.id}
          data-testid={`${dataTestid}.channel-lock-indicator`}
          focused={focused}
        />
      </ComponentsChannelItem>
    </MouseNavigationContext.Provider>
  );
}
