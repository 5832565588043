import { useAtomValue } from "jotai";

import { getLiveProgress, nowAtom } from "@sunrise/time";
import { useTranslator } from "@sunrise/translator";
import { type Nullable } from "@sunrise/utils";

import { makeRelativeDayOfWeekTranslation } from "../helpers/make-relative-day-of-week-translation";
import { useReadableEpgSchedulingInfo } from "./use-readable-epg-scheduling-info";

type EpgRelativeTimeArgs = {
  startDate: Nullable<Date>;
  endDate: Nullable<Date>;
  expiresAt: Nullable<Date>;
  forceLiveProgress?: boolean;
};
type EpgRelativeTime = {
  formattedStart: Nullable<string>;
  formattedEnd: Nullable<string>;
  airTime: Nullable<string>;
  /**
   * @deprecated. See `useContinueWatchingProgress` or a relevant hook which is designed to fill the progressbar.
   */
  liveProgress?: Nullable<number>;
  expiresIn?: Nullable<number>; // days
  isFinished: boolean;
};

export const ONE_DAY_IN_MS = 24 * 60 * 60 * 1000; // one day = hours*minutes*seconds*milliseconds

export const useEpgRelativeTime = ({
  startDate,
  endDate,
  expiresAt: expiresInDate,
  forceLiveProgress,
}: EpgRelativeTimeArgs): EpgRelativeTime => {
  const t = useTranslator();
  const now = useAtomValue(nowAtom);

  const { formattedEnd, formattedStart } = useReadableEpgSchedulingInfo(
    startDate && endDate ? { startTime: startDate, endTime: endDate } : null,
    t,
    now,
  );

  if (!startDate || !endDate)
    return {
      formattedStart: null,
      formattedEnd: null,
      airTime: null,
      liveProgress: null,
      expiresIn: null,
      isFinished: false,
    };

  const relativeDay = makeRelativeDayOfWeekTranslation(
    startDate,
    endDate,
    now,
    t,
  );

  const expiresIn = expiresInDate
    ? Math.round(
        Math.abs((expiresInDate.getTime() - now.getTime()) / ONE_DAY_IN_MS),
      )
    : null;

  const liveProgress = getLiveProgress(
    startDate,
    endDate,
    now,
    forceLiveProgress,
  );
  const isFinished = now >= endDate;

  return {
    formattedStart,
    formattedEnd,
    airTime: relativeDay,
    expiresIn,
    liveProgress,
    isFinished,
  };
};
