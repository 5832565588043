import { atomWithSuspenseQuery } from "jotai-tanstack-query";

import type { Nullable } from "@sunrise/utils";

import { queryKeys } from "./query-keys";
import { getTizenProductInfo, type ProductInfo } from "./system.service";

export const tizenProductInfoAtom = atomWithSuspenseQuery<
  Nullable<ProductInfo>
>(() => {
  return {
    queryKey: queryKeys.productInfo,
    queryFn: async () => {
      try {
        return await getTizenProductInfo();
      } catch {
        return null;
      }
    },
    cacheTime: Infinity,
    staleTime: Infinity,
    keepPreviousData: true,
  };
});
tizenProductInfoAtom.debugLabel = "tizenProductInfoAtom";
