import { atomWithStorage, createJSONStorage } from "jotai/utils";
import { withAtomEffect } from "jotai-effect";

import {
  RecordingItemFilter,
  recordingsOverviewFilterAtom,
  recordingsOverviewStatusAtom,
  RecordingStatus,
} from "@sunrise/backend-ng-recordings";

import { RecordingOverviewFilter } from "./types";

/**
 * Mapping of local filters to backend values
 * The client only exposes one filter that can have one value selected at a single time
 * These individual values map to multiple properties on the API
 *
 * Mapping based on iOS implementation https://bitbucket.org/entwicklungspark/yallotv-ios/src/1090e06adf9e/packages/PlayerContent/Tests/PlayerContentTests/RecordingFilterTests.swift
 */
const overviewClientFiltersToApi: Record<
  RecordingOverviewFilter,
  { filter: RecordingItemFilter; status?: RecordingStatus }
> = {
  [RecordingOverviewFilter.All]: {
    filter: RecordingItemFilter.All,
    status: RecordingStatus.All,
  },
  [RecordingOverviewFilter.AllRecorded]: {
    filter: RecordingItemFilter.All,
    status: RecordingStatus.Recorded,
  },
  [RecordingOverviewFilter.Recording]: {
    filter: RecordingItemFilter.Recording,
    status: undefined,
  },
  [RecordingOverviewFilter.RecordingGroup]: {
    filter: RecordingItemFilter.RecordingGroup,
    status: undefined,
  },
  [RecordingOverviewFilter.Planned]: {
    filter: RecordingItemFilter.All,
    status: RecordingStatus.Planned,
  },
};

const storage = createJSONStorage<RecordingOverviewFilter>(
  () => sessionStorage,
);
/**
 * Controls which filter to apply for the recordings overview.
 * Automatically syncs with backend filters/status atoms
 */
export const recordingsOverviewClientFilterAtom = withAtomEffect(
  atomWithStorage<RecordingOverviewFilter>(
    "recordings-filter",
    RecordingOverviewFilter.All,
    storage,
  ),
  (get, set) => {
    const filter = get(recordingsOverviewClientFilterAtom);

    set(
      recordingsOverviewFilterAtom,
      overviewClientFiltersToApi[filter].filter,
    );
    set(
      recordingsOverviewStatusAtom,
      overviewClientFiltersToApi[filter].status,
    );
  },
);
