import { useCallback } from "react";
import { useAtomValue, useSetAtom } from "jotai";
import { useAtomCallback } from "jotai/utils";

import { logButtonClickAtom } from "@sunrise/analytics";
import type { Coordinates } from "@sunrise/bigscreen";
import { isNil } from "@sunrise/utils";
import {
  actionPlayerManagerPlayLiveChannelId,
  playerManagerAtom,
} from "@sunrise/yallo-common-player-manager";
import { epgDetailsForEpgEntryId } from "@sunrise/yallo-epg";
import { recommendationsDataLegacyAtom } from "@sunrise/yallo-recommendations";

import { currentlySelectedItemAtom } from "@/features/recommendations/currently-selected-item.atom";
import { itemAtRecommendationCoordinatesAtom } from "@/features/recommendations/item-at-recommendation-coordinates.atom";
import {
  actionRecommendationsSetCoordinates,
  recommendationsAtom,
  selectRecommendationsCoordinates,
} from "@/features/recommendations/recommendations.atom";
import { useRoutes } from "@/features/routing/use-routes";
import { actionMenuExpand, menuAtom } from "@/modules/menu";
import {
  isArrowDownKey,
  isArrowLeftKey,
  isArrowRightKey,
  isArrowUpKey,
} from "@/utils/navigation";

/**
 * Purely the navigation and selection logic for the recommendations.
 * Does not contain automatic re-selection.
 *
 * @returns
 */
export function useRecommendationsNavigation(): {
  onArrow: (direction: string) => boolean;
  onEnter: () => void;
  focusElement: (coordinates: Coordinates) => void;
} {
  const itemAtCoordinates = useAtomValue(itemAtRecommendationCoordinatesAtom);

  const routes = useRoutes();

  const dispatchMenu = useSetAtom(menuAtom);
  const expandMenu = useCallback(
    () => dispatchMenu(actionMenuExpand()),
    [dispatchMenu],
  );

  const dispatchFocusedItem = useSetAtom(recommendationsAtom);

  return {
    focusElement: useAtomCallback(
      useCallback(
        (_get, _, coordinates) => {
          dispatchFocusedItem(
            actionRecommendationsSetCoordinates({
              row: coordinates.rowIndex,
              column: coordinates.colIndex,
            }),
          );
        },
        [dispatchFocusedItem],
      ),
    ),
    onArrow: useAtomCallback(
      useCallback(
        (get, _, direction: string) => {
          const currentCoordinates = get(selectRecommendationsCoordinates);
          if (isNil(currentCoordinates)) {
            return false;
          }

          let newCoordinates: Coordinates | null = null;

          if (isArrowLeftKey(direction)) {
            if (currentCoordinates.colIndex === 0) {
              // When first item, expand menu.
              expandMenu();
              return false;
            }

            newCoordinates = {
              rowIndex: currentCoordinates.rowIndex,
              colIndex: currentCoordinates.colIndex - 1,
            };
          } else if (isArrowRightKey(direction)) {
            newCoordinates = {
              rowIndex: currentCoordinates.rowIndex,
              colIndex: currentCoordinates.colIndex + 1,
            };
          } else if (isArrowUpKey(direction)) {
            const rowIndex = currentCoordinates.rowIndex - 1;

            // First we try to go to the upper row's last known selected column.
            newCoordinates = {
              rowIndex,
              colIndex:
                get(recommendationsAtom).coordinates.colIndex[rowIndex] ?? 0,
            };

            // But if it does not exist, we go to the first column.
            if (!itemAtCoordinates(newCoordinates)) {
              newCoordinates = {
                rowIndex,
                colIndex: 0,
              };
            }
          } else if (isArrowDownKey(direction)) {
            const rowIndex = currentCoordinates.rowIndex + 1;

            // First we try to go to the lower row's last known selected column.
            newCoordinates = {
              rowIndex,
              colIndex:
                get(recommendationsAtom).coordinates.colIndex[rowIndex] ?? 0,
            };

            // But if it does not exist, we go to the first column.
            if (!itemAtCoordinates(newCoordinates)) {
              newCoordinates = {
                rowIndex,
                colIndex: 0,
              };
            }
          } else {
            return false;
          }

          const item = itemAtCoordinates(newCoordinates);
          if (!item) {
            return false;
          }

          // Store the newly focused item.
          dispatchFocusedItem(
            actionRecommendationsSetCoordinates({
              row: newCoordinates.rowIndex,
              column: newCoordinates.colIndex,
            }),
          );

          return false;
        },
        [expandMenu, itemAtCoordinates, dispatchFocusedItem],
      ),
    ),
    onEnter: useAtomCallback(
      useCallback(
        (get, set) => {
          const handler = async (): Promise<void> => {
            const item = await get(currentlySelectedItemAtom);
            const data = await get(recommendationsDataLegacyAtom);
            const location = get(selectRecommendationsCoordinates);
            const row = data[location.rowIndex];

            if (!item) {
              return;
            }

            switch (item.type) {
              case "channel":
                await get(logButtonClickAtom).invoke({
                  type: "to_channel_item",
                  pageId: "home_page",
                  channelId: item.details.id,
                  categoryId: row?.category,
                });
                set(
                  playerManagerAtom,
                  actionPlayerManagerPlayLiveChannelId(item.details.id),
                );
                break;
              case "epg":
                await get(logButtonClickAtom).invoke({
                  type: "to_epg_item",
                  pageId: "home_page",
                  epgId: item.details.id,
                  categoryId: row?.category,
                  componentType:
                    row?.kind !== "channel" ? row?.kind : undefined,
                });
                routes.details.root({
                  epgId: item.details.id,
                  assetId: item.details.asset.id,
                });
                break;

              case "recording":
              case "replay": {
                await get(logButtonClickAtom).invoke({
                  type: "to_epg_item",
                  pageId: "home_page",
                  epgId: item.epg_entry.id,
                  categoryId: row?.category,
                  componentType:
                    row?.kind !== "channel" ? row?.kind : undefined,
                });
                const epgItem = await get(
                  epgDetailsForEpgEntryId({ epgId: item.epg_entry.id }),
                );

                routes.details.root({
                  epgId: item.epg_entry.id,
                  assetId: epgItem.asset.id,
                });
                break;
              }
            }
          };

          void handler();
        },
        [routes],
      ),
    ),
  };
}
