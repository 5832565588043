import { Suspense } from "react";
import { useAtomValue } from "jotai";
import { isNil } from "lodash";
import gradient from "static/gradients/details-page.webp";

import { useKeyboardNavigation } from "@sunrise/bigscreen";
import { detailsAtom } from "@sunrise/details";
import { selectIsDialogOpen } from "@sunrise/dialogs";
import { isDefined } from "@sunrise/utils";
import { epgEntryByIdAtom } from "@sunrise/yallo-epg";

import { PageSpinner, Spinner } from "@/components";
import type { routeParam } from "@/config/route";
import { DetailsInfo } from "@/features/details/details-info/details-info";
import { DetailsTagsRecording } from "@/features/details/details-tags/details-tags-recording";
import { useMenu } from "@/features/menu/use-menu";
import { ErrorBoundary as SentryErrorBoundary } from "@/features/monitoring/error-boundary";
import { useRoutes } from "@/features/routing/use-routes";

import { ButtonListError } from "./button-list-error";
import * as styles from "./details.css";
import { DetailsButtonList } from "./details-button-list";
import { MissingDetails } from "./missing-details";

type DetailsPageProps = {
  params: typeof routeParam.details;
};

export function Details(props: DetailsPageProps): JSX.Element {
  // It's forbidden to show menu on this page
  useMenu({ hidden: true });

  // TODO: This could be moved to a general hook and called whenever
  // element is focused to prefetch data
  // onEnterPress -> change id therefore start prefetching,
  // onEnterRelease -> dispatch navigation action (could be done with timeout)
  // Problem is that nothing is actually listening on the derived atoms to display the details. And so it won't actually preload anything.
  const isDialogOpen = useAtomValue(selectIsDialogOpen);

  // NOTE: ensure that back works while data is loading, but not when confirmation dialog is open
  // TODO: find better way to still enable Back while component is loading, but not focus is not on the component
  const routes = useRoutes();
  useKeyboardNavigation({
    onBack: routes.back,
    isEnabled: !isDialogOpen,
  });

  return (
    <Suspense fallback={<PageSpinner data-testid="details" />}>
      <SuspendedContent params={props.params} />
    </Suspense>
  );
}

const TEST_ID = "details-page";

type SuspendedContentProps = {
  params: typeof routeParam.details;
};

function SuspendedContent({ params }: SuspendedContentProps) {
  const details = useAtomValue(detailsAtom(params));
  const epgResult = useAtomValue(epgEntryByIdAtom(params.epgId));
  const epg = epgResult.data;

  if (!epg || isNil(details)) {
    return <MissingDetails />;
  }

  const {
    title,
    channelId,
    subtitle,
    channelLogo,
    tags,
    description,
    time,
    backgroundImage,
  } = details;

  if (isNil(channelId)) {
    return <MissingDetails />;
  }

  return (
    <div className={styles.mainWrapper}>
      <main
        className={styles.main}
        data-testid={TEST_ID}
        style={{
          backgroundImage: `url(${gradient}), url(${backgroundImage})`,
          backgroundRepeat: `repeat, no-repeat`,
          backgroundSize: "contain, cover",
          backgroundPositionX: `left, center`,
        }}
        tabIndex={0}
      >
        <div className={styles.wrapper}>
          <div className={styles.tagsWrapper}>
            <Suspense>
              <DetailsTagsRecording
                assetId={params.assetId}
                data-testid={`${TEST_ID}.details-tags`}
                epgId={epg.id}
                tags={[tags.age, tags.year, ...tags.genres].filter(isDefined)}
              />
            </Suspense>
          </div>
          <DetailsInfo
            assetId={params.assetId}
            channelLogo={channelLogo}
            data-testid={`${TEST_ID}.details-info`}
            description={description}
            showCastDirector={true}
            subtitle={subtitle.label}
            time={time}
            title={title}
          />

          <div className={styles.buttonList}>
            <SentryErrorBoundary
              fallback={({ eventId }) => <ButtonListError eventId={eventId} />}
            >
              <Suspense
                fallback={
                  <div className={styles.actions}>
                    <Spinner />
                  </div>
                }
              >
                <DetailsButtonList
                  channelId={channelId}
                  data-testid={`${TEST_ID}.details-button-list`}
                  epg={epg}
                  time={time}
                />
              </Suspense>
            </SentryErrorBoundary>
          </div>
        </div>
      </main>
    </div>
  );
}
