import { captureException } from "@sentry/react";
import { atom } from "jotai";

import {
  actionKeyboardNavigationRegisterMultipleKeyCodes,
  type AppInitConfig,
  developerDeviceInfoAtom,
  keyboardNavigationAtom,
  readMemoryAtom,
} from "@sunrise/bigscreen";
import type { Store } from "@sunrise/store";

import { makeMonitoringExtraData, makeMonitoringTags } from "./monitoring";
import { readTizenMemoryStatus, tizenProductInfoAtom } from "./tizen";
import {
  closeTizenApp,
  readTizenPlatformVersion,
} from "./tizen/system.service";
import { tizenDeveloperDeviceInfoAtom } from "./tizen/tizen-developer-device-info.atom";
import { tizenOfflineAtom } from "./tizen/tizen-offline.atom";
import { initTvRemote } from "./tizen/tv-remote.service";

export async function tizenConfig(store: Store): Promise<AppInitConfig> {
  store.set(readMemoryAtom, () => readTizenMemoryStatus);
  store.set(
    developerDeviceInfoAtom,
    await store.get(tizenDeveloperDeviceInfoAtom),
  );

  return {
    offlineAtom: tizenOfflineAtom,
    deviceIdAtom: atom(
      async (get) => (await get(tizenProductInfoAtom)).data?.duid,
    ),
    deviceVersion: readTizenPlatformVersion(),
    getDeviceTags: async () => {
      const info = await store.get(tizenProductInfoAtom);
      const deviceId = info.data?.duid;

      // We are setting these things as tags since we can search on tags.
      return {
        "device.id": deviceId,
        "device.model": info.data?.model,
        "device.model_code": info.data?.modelCode,
        "device.real_model": info.data?.realModel,
        "device.firmware": info.data?.firmware,
        ...makeMonitoringTags(),
      };
    },
    getDeviceExtras: async () => makeMonitoringExtraData(),
    initRemote: (store, isProduction) => {
      initTvRemote({
        onKeyRegistrationError: (msg) => {
          if (isProduction) captureException(msg);
        },
        onValidationError: (msg) => {
          if (isProduction) captureException(msg);
        },
        registerKeys: (tvRemoteKeyCodes) => {
          store.set(
            keyboardNavigationAtom,
            actionKeyboardNavigationRegisterMultipleKeyCodes({
              Back: [tvRemoteKeyCodes.Back],
              Exit: [tvRemoteKeyCodes.Exit],
              ChannelDown: [tvRemoteKeyCodes.ChannelDown],
              ChannelUp: [tvRemoteKeyCodes.ChannelUp],
              Info: [tvRemoteKeyCodes.Info],
              Caption: [tvRemoteKeyCodes.Caption],
              Guide: [tvRemoteKeyCodes.Guide],
              MediaAdvance: [tvRemoteKeyCodes.MediaAdvance],
              MediaPause: [tvRemoteKeyCodes.MediaPause],
              MediaPlay: [tvRemoteKeyCodes.MediaPlay],
              MediaPlayPause: [tvRemoteKeyCodes.MediaPlayPause],
              MediaFastForward: [tvRemoteKeyCodes.MediaFastForward],
              MediaRewind: [tvRemoteKeyCodes.MediaRewind],
              ProgrammableF0: [tvRemoteKeyCodes.ColorF0Red],
              ProgrammableF1: [tvRemoteKeyCodes.ColorF1Green],
              ProgrammableF2: [tvRemoteKeyCodes.ColorF2Yellow],
              ProgrammableF3: [tvRemoteKeyCodes.ColorF3Blue],
              "0": [tvRemoteKeyCodes["0"]],
              "1": [tvRemoteKeyCodes["1"]],
              "2": [tvRemoteKeyCodes["2"]],
              "3": [tvRemoteKeyCodes["3"]],
              "4": [tvRemoteKeyCodes["4"]],
              "5": [tvRemoteKeyCodes["5"]],
              "6": [tvRemoteKeyCodes["6"]],
              "7": [tvRemoteKeyCodes["7"]],
              "8": [tvRemoteKeyCodes["8"]],
              "9": [tvRemoteKeyCodes["9"]],
            }),
          );
        },
      });
    },
    closeApp: closeTizenApp,
  };
}
