import { type ReactNode, Suspense } from "react";
import type { VirtualItem } from "@tanstack/react-virtual";

import type {
  ChannelResultItem,
  ContinueWatchingItemLegacy,
  ProgramResultItem,
} from "@sunrise/backend-types";

import { ListChannelItem, ListEpgItem } from "@/components";
import * as styles from "@/features/recommendations/recommendations.css";

export type VirtualListRowItemProps = {
  virtualItem: VirtualItem;
  item: ChannelResultItem | ProgramResultItem | ContinueWatchingItemLegacy;
  "data-testid": string;
  index: number;
  isFocused: boolean;
  kind: "normal" | "large" | "channel";
};

export function VirtualListRowItem({
  virtualItem,
  item,
  "data-testid": dataTestId,
  isFocused,
  index,
  kind,
}: VirtualListRowItemProps): ReactNode {
  const navId = {
    rowIndex: index,
    colIndex: virtualItem.index,
  };

  return (
    <div
      className={styles.virtualItem}
      style={{
        width: `${virtualItem.size}px`,
        transform: `translateX(${virtualItem.start}px)`,
      }}
    >
      {item.type === "channel" ? (
        <Suspense>
          <ListChannelItem
            data-testid={`${dataTestId}.${index}.item.${virtualItem.index}`}
            item={item}
            navId={navId}
            focused={isFocused}
            // TODO: provide value from backend, even if null
            posterUrl={null}
          />
        </Suspense>
      ) : (
        <Suspense>
          <ListEpgItem
            data-testid={`${dataTestId}.${index}.item.${virtualItem.index}`}
            focused={isFocused}
            item={item}
            kind={kind}
            navId={navId}
          />
        </Suspense>
      )}
    </div>
  );
}
