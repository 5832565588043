import { useCallback, useEffect, useMemo, useRef } from "react";
import { useAtomValue } from "jotai";
import { throttle } from "lodash";

import { isNil } from "@sunrise/utils";

import {
  type InAppKeyboardKeyId,
  selectKeyboardNavigationKeyCodeKeyIdDict,
} from "./keyboard-navigation.store";

type ArrowHandler = (direction: "left" | "right" | "up" | "down") => void;
type KeyHandler = () => void;

type NumberHandler = (number: InAppKeyboardKeyId) => void;

const DIRECTION_MAP = {
  ArrowLeft: "left",
  ArrowRight: "right",
  ArrowUp: "up",
  ArrowDown: "down",
} as const;

export type KeyboardNavigationCallbackRecord = Partial<
  Record<`on${InAppKeyboardKeyId}`, KeyHandler> &
    Record<"onArrow", ArrowHandler> &
    Record<"onNumber", NumberHandler>
>;

export type KeyboardNavigationOptionRecord = {
  isEnabled: boolean;
  repeatThrottle?: number;
};

function isArrowKey(
  keyId: InAppKeyboardKeyId,
): keyId is "ArrowLeft" | "ArrowRight" | "ArrowUp" | "ArrowDown" {
  return (
    keyId === "ArrowLeft" ||
    keyId === "ArrowRight" ||
    keyId === "ArrowUp" ||
    keyId === "ArrowDown"
  );
}

/**
 * NOTE: Backspace will be mapped to "8" (event.keyCode / event.which) which causes issues
 */
function isForbiddenKey(keyId: InAppKeyboardKeyId): keyId is "Backspace" {
  return keyId === "Backspace";
}

function isNumberKey(keyId: InAppKeyboardKeyId): boolean {
  return !isNaN(Number(keyId));
}

/**
 * TODO: Add logging capability which can emit all keypresses as breadcrumbs.
 */
export function useKeyboardNavigation(
  config: KeyboardNavigationCallbackRecord & KeyboardNavigationOptionRecord,
): void {
  const configRef = useRef(config);
  useEffect(() => {
    configRef.current = config;
  }, [config]);

  const dict = useAtomValue(selectKeyboardNavigationKeyCodeKeyIdDict);
  const lastKeyRef = useRef<InAppKeyboardKeyId | null>(null);

  /**
   * Will perform the call. Either throttled or not depending on the configuration.
   */
  const throttler = useMemo(() => {
    if (config.repeatThrottle) {
      return throttle((fn: () => void) => fn(), config.repeatThrottle, {
        leading: true,
        trailing: false,
      });
    }

    return (fn: () => void) => fn();
  }, [config.repeatThrottle]);

  const triggerKey = useCallback(
    (keyId: InAppKeyboardKeyId, isRepeat: boolean) => {
      const pureKeyCall = (): void => configRef.current[`on${keyId}`]?.();

      const call = isArrowKey(keyId)
        ? () => {
            if (`on${keyId}` in configRef.current) {
              configRef.current[`on${keyId}`]?.();
              return;
            }

            const mapped = DIRECTION_MAP[keyId];
            configRef.current.onArrow?.(mapped);
          }
        : isNumberKey(keyId)
          ? () => {
              configRef.current.onNumber?.(keyId);
            }
          : pureKeyCall;

      if (isRepeat && lastKeyRef.current === keyId) {
        throttler(call);
        return;
      }

      lastKeyRef.current = keyId;
      call();
    },
    [throttler],
  );

  const keyDownHandler = useCallback(
    (e: KeyboardEvent) => {
      const keyId =
        dict[e.code in dict ? e.code : e.key] ??
        dict[e.keyCode in dict ? e.keyCode : e.which]; // NOTE: backwards compatibility for webOS

      if (
        e.ctrlKey ||
        e.altKey ||
        e.metaKey ||
        isNil(keyId) ||
        isForbiddenKey(keyId)
      ) {
        lastKeyRef.current = null;
        return;
      }

      e.preventDefault();
      triggerKey(keyId, e.repeat);
    },
    [dict, triggerKey],
  );

  useEffect(
    function attachEventListener() {
      if (!config.isEnabled) return;

      window.addEventListener("keydown", keyDownHandler);
      return () => window.removeEventListener("keydown", keyDownHandler);
    },
    [keyDownHandler, config.isEnabled],
  );
}
