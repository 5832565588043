import { forwardRef, type ReactElement, useMemo } from "react";
import { atom, useAtomValue } from "jotai";

import type { ProgramResult, ProgramResultItem } from "@sunrise/backend-types";
import type { NavigationId } from "@sunrise/bigscreen";
import { currentLanguageAtom, getLocalizedValue } from "@sunrise/i18n";
import type { Nullable } from "@sunrise/utils";
import { useStableDate } from "@sunrise/utils";
import { channelByIdAtom } from "@sunrise/yallo-channel-group";
import {
  continueWatchingStatusByEpgIdAtom,
  getContinueWatchingProgress,
} from "@sunrise/yallo-continue-watching";
import { epgEntryByIdAtom, useEpgRelativeTime } from "@sunrise/yallo-epg";
import {
  recordingByEpgIdAtom,
  recordingStatusByEpgIdAtom,
} from "@sunrise/yallo-recordings";

import { ProgramBox } from "@/components/boxes";
import { programBoxSize } from "@/config/size";
import { makePosterImgUrl } from "@/utils/image";

import { ChannelLockIndicator } from "../../features/channel/channel-lock-indicator";
import * as styles from "./list-epg-item.css";

export type ListEpgItemProps = CommonProps & {
  item: ProgramResult["items"][number];
  kind: "normal" | "large" | "channel";
  active?: boolean;
  navId: NavigationId;
  focused: boolean;
  "data-position"?: number;
};

const NO_DATA_ATOM = atom({ data: null });

/**
 * Regular program card used in the Recommendations section for example.
 */
export const ListEpgItem = forwardRef<HTMLDivElement, ListEpgItemProps>(
  function ListEpgItem(
    {
      "data-testid": dataTestId = "ColumnItemProgram",
      "data-position": dataPosition,
      focused,
      navId,
      ...props
    },
    ref,
  ): ReactElement {
    if (props.kind === "channel") {
      throw new Error(
        "ColumnItemProgram should not be used with 'channel' kind",
      );
    }

    const cwItem = props.item.type !== "epg" ? props.item : null;
    const epgItem = useAtomValue(
      cwItem ? epgEntryByIdAtom(cwItem.epg_entry.id) : NO_DATA_ATOM,
    ).data;

    const itemDetails = epgItem
      ? epgItem
      : (props.item as ProgramResultItem).details;

    const start = useStableDate(
      epgItem
        ? epgItem.actualStart
        : (props.item as ProgramResultItem).details.actual_start,
    ) as Date;

    const end = useStableDate(
      epgItem
        ? epgItem.actualEnd
        : (props.item as ProgramResultItem).details.actual_end,
    ) as Date;

    const { formattedStart, formattedEnd, airTime, liveProgress } =
      useEpgRelativeTime({
        startDate: start,
        endDate: end,
        expiresAt: null,
      });

    const asset = itemDetails?.asset;

    const language = useAtomValue(currentLanguageAtom);

    const title =
      !asset?.title || typeof asset?.title === "string"
        ? (asset.title as Nullable<string>)
        : getLocalizedValue(asset.title, language);

    const subtitle =
      !asset?.subtitle || typeof asset?.subtitle === "string"
        ? (asset.subtitle as Nullable<string>)
        : getLocalizedValue(asset.subtitle, language);

    const coverImageUrl =
      "posterPath" in asset ? asset.posterPath : asset.poster_path;

    const posterUrl = useMemo(
      () =>
        makePosterImgUrl(
          coverImageUrl,
          programBoxSize[props.kind].image.width,
          programBoxSize[props.kind].image.height,
        ),
      [coverImageUrl, props.kind],
    );

    const channelId = itemDetails.channel.id;

    const channel = useAtomValue(channelByIdAtom(channelId));
    const logoUrl = channel?.logo;

    /**
     * Loads in the recording. But it will only happen when there's actually a recording status for this program.
     */
    const recording = useAtomValue(recordingByEpgIdAtom(itemDetails.id));

    // TODO: swap over to hook for progress? On Tizen we may have a recording for an epgId. And we would then need the padding information.
    const status = useAtomValue(
      continueWatchingStatusByEpgIdAtom(itemDetails.id),
    );

    const continueWatchingProgress = useMemo(() => {
      if (recording?.type === "group") {
        return null;
      }

      return getContinueWatchingProgress({
        status,
        epgEndTime: end,
        epgStartTime: start,
        paddingTimesInMinutes: recording
          ? {
              end: recording.paddingEndMinutes ?? 0,
              start: recording.paddingStartMinutes ?? 0,
            }
          : null,
      });
    }, [status, start, end, recording]);

    const recordingStatus = useAtomValue(
      recordingStatusByEpgIdAtom(itemDetails.id),
    );

    return (
      <ProgramBox
        ref={ref}
        active={props.active}
        airTime={airTime}
        coverImageUrl={posterUrl}
        data-focused={focused}
        data-position={dataPosition}
        data-testid={dataTestId}
        end={formattedEnd}
        endDate={end}
        focused={focused}
        kind={props.kind}
        liveProgress={liveProgress}
        logoUrl={logoUrl}
        navId={navId}
        recordingState={recordingStatus?.status}
        replayProgress={continueWatchingProgress}
        start={formattedStart}
        startDate={start}
        subtitle={subtitle}
        title={title}
      >
        {!recordingStatus ? (
          <ChannelLockIndicator
            borderRadius={12}
            channelId={channelId}
            className={styles.lockIndicator}
            data-testid={`${dataTestId}-${channelId}.channel-lock-indicator`}
          />
        ) : null}
      </ProgramBox>
    );
  },
);
