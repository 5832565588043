import { useCallback, useLayoutEffect, useRef, useState } from "react";
import { useAtomValue } from "jotai";

import type { Nullable } from "@sunrise/utils";
import { loadImageAsBase64 } from "@sunrise/utils";

import { selectPlayerCurrentSeekTime } from "../selectors";
import {
  type Thumbnail,
  thumbnailGeneratorAtom,
  type ThumbnailSprite,
} from "./thumbnail-generator.atom";

type ThumbnailQueueItem = {
  thumbnail: ThumbnailSprite;
  seekTime: number;
};

export type UseThumbnailProps = {
  /**
   * The desired width of the thumbnail. When not provided, the original size will be used.
   */
  width?: Nullable<number>;
  useBase64?: boolean;
};

/**
 * This hook will return the current thumbnail for the current seek time.
 */
export function useThumbnail(props?: UseThumbnailProps) {
  const desiredWidth = props?.width;
  const useBase64 = props?.useBase64;

  const thumbGen = useAtomValue(thumbnailGeneratorAtom);
  const seekTime = useAtomValue(selectPlayerCurrentSeekTime);
  const seekTimeRef = useRef(seekTime);
  const [imageBase64, setImagebase64] = useState<string | null>(null);

  // TODO: We can potentially do some preloading of the images.
  //       We should determine the seek direction and then preload the next X sprites.
  //       The generator should expose functionality where we can ask for the next URL as well if we pass in the direction.
  //       It should be able to know this since it should know how many frames are in a sprite and what the current frame is.

  const loadingThumbRef = useRef<ThumbnailQueueItem | null>(null);
  const nextThumbRef = useRef<ThumbnailQueueItem | null>(null);

  const [thumbnail, setThumbnail] = useState<Thumbnail | null>(null);
  const setResizedThumbnail = useCallback(
    (value: ThumbnailSprite | null) => {
      if (!value) {
        setThumbnail(null);
        return;
      } else if (!desiredWidth) {
        setThumbnail({
          ...value,
          resizeRatio: 1,
        });
        return;
      }

      // We need to do some resizing here.
      const resizeRatio = desiredWidth / value.width;
      setThumbnail({
        ...value,
        width: desiredWidth,
        height: value.height * resizeRatio,
        resizeRatio,
      });
    },
    [desiredWidth],
  );

  const setNoThumbnail = useCallback(() => {
    loadingThumbRef.current = null;
    nextThumbRef.current = null;
    setThumbnail(null);
  }, []);

  /**
   * Processes the thumbnails in order.
   * The idea is that every thumb we add, it will be loaded. And we will display the last requested location for that thumb in the Thumbnail.
   * Once the thumb is loaded, we will process the next one.
   *
   * When we are idle for a while, we will abort processing the queue and just process the last one. But as long as we are seeking, we will process the queue.
   * We may add a mechanism to timeout certain requests if they take too long.
   */
  const process = useCallback(async (): Promise<void> => {
    // We are currently still loading something so processing is not needed.
    if (loadingThumbRef.current || !nextThumbRef.current) {
      return;
    }

    // We indicate we are loading a new thumbnail.
    loadingThumbRef.current = nextThumbRef.current;

    try {
      if (useBase64) {
        const base64 = await loadImageAsBase64(
          loadingThumbRef.current.thumbnail.url,
        );
        // When it is loaded, swap over the new image contents.
        setImagebase64(base64);
      }

      if (
        nextThumbRef.current.thumbnail.url ===
        loadingThumbRef.current.thumbnail.url
      ) {
        // When the next thumb is actually for the exact same URL, we can just swap it over.
        // And also empty out the nextThumbRef.
        setResizedThumbnail(nextThumbRef.current.thumbnail);
        nextThumbRef.current = null;
        loadingThumbRef.current = null;
        return;
      }

      // But if the next thumb is different, we will just move over the loaded thumbnail to the current one.
      setResizedThumbnail(loadingThumbRef.current.thumbnail);
      loadingThumbRef.current = null;
      // And process the next one.
      process();
    } catch (_e) {
      // When stuff crashes ... we just set the thumbnail to null. It probably means the thumbnails do not work at all anyway.
      setNoThumbnail();
    }
  }, [useBase64, setNoThumbnail, setResizedThumbnail]);

  useLayoutEffect(() => {
    const run = async (): Promise<void> => {
      seekTimeRef.current = seekTime;

      if (!seekTime || !thumbGen) {
        setNoThumbnail();
        return;
      }

      const thumb = await thumbGen.generate(seekTime);
      if (!thumb) {
        setNoThumbnail();
        return;
      }

      if (seekTimeRef.current === seekTime) {
        nextThumbRef.current = { thumbnail: thumb, seekTime };
        process();
      }
    };

    run();
  }, [thumbGen, seekTime, process, setNoThumbnail]);

  return {
    imageBase64,
    thumbnail,
  };
}
