import { keepPreviousData } from "@tanstack/query-core";
import { atom } from "jotai";
import { atomWithSuspenseQuery } from "jotai-tanstack-query";

import { ngContinueWatchingApiAtom } from "@sunrise/backend-ng-continue-watching";
import { queryKeys } from "@sunrise/backend-types";
import { selectJwtUserToken } from "@sunrise/jwt";
import { isDefined } from "@sunrise/utils";

import { mapBackendContinueWatchingNg } from "./map-backend-continue-watching.ng";

/**
 * This is an atom that is used to display the continue watching items on the home page as recommendations.
 * We will only ever return the first X items and never more. It'll also be mapped to MappedContinueWatchingItems? No just MappedEpgItems|MappedRecordingItems, right?
 */
export const continueWatchingRecommendationItemsNgAtom = atomWithSuspenseQuery(
  (get) => {
    const ngApi = get(ngContinueWatchingApiAtom);

    return {
      queryKey: queryKeys.continueWatchingRecommendations(
        get(selectJwtUserToken),
      ),
      queryFn: async () => {
        const continueWatchingEntries =
          await ngApi.continueWatching.getContinueWatchingForUserContinueWatchingV1ContinueWatchingEntriesGet(
            { page: 1, size: 50 },
          );

        return continueWatchingEntries.data.items
          .map(mapBackendContinueWatchingNg)
          .filter((e) => isDefined(e));
      },
      placeholderData: keepPreviousData,
      staleTime: 0,
      gcTime: 60 * 1000 * 5,
      refetchOnWindowFocus: true,
    };
  },
);

export const continueWatchingRecommendationDataAtom = atom(async (get) => {
  const query = await get(continueWatchingRecommendationItemsNgAtom);

  const items = query?.data;

  if (!items?.length) {
    return null;
  }

  return items;
});
