import { useEffect } from "react";
import type { DefinedQueryObserverResult } from "@tanstack/query-core";
import { type Atom, useStore } from "jotai";

type PromiseOrValue<T> = Promise<T> | T;

export function useRefetchAtom(
  atom: Atom<PromiseOrValue<DefinedQueryObserverResult<unknown, unknown>>>,
): void {
  const store = useStore();
  useEffect(() => {
    Promise.resolve(store.get(atom)).then((result) => {
      result.refetch();
    });
  }, [atom, store]);
}
