import { type ReactNode } from "react";

import { useKeyboardNavigation } from "@sunrise/bigscreen";
import type { ActionsDialog } from "@sunrise/dialogs";
import { useTranslatable } from "@sunrise/translator";

import { SideBar } from "@/components";

import { SideBarActionsButton } from "./side-bar-actions-button";
import * as styles from "./side-bar-actions-dialog.css";

export type SideBarActionsDialogProps = ActionsDialog & {
  doClose: () => void;
};

export function SideBarActionsDialog({
  title,
  actions,
  doClose,
}: SideBarActionsDialogProps): ReactNode {
  const t = useTranslatable();
  const focusIndex = actions.findIndex((item) => !!item.initialFocus);
  const preferredChildFocusKey =
    focusIndex >= 0
      ? `side-bar-actions-dialog.button.${focusIndex}`
      : undefined;

  useKeyboardNavigation({
    onArrowLeft: doClose,
    isEnabled: true,
  });

  return (
    <SideBar
      data-testid="side-bar-actions-dialog"
      focusKey="side-bar-actions-dialog.container"
      preferredChildFocusKey={preferredChildFocusKey}
      shouldShow={true}
    >
      {(_handlers) => (
        <>
          <div className={styles.title}>{t(title)}</div>
          {actions.map((action, index) => (
            <SideBarActionsButton
              key={index.toString()}
              {...action}
              doClose={doClose}
              index={index}
            />
          ))}
        </>
      )}
    </SideBar>
  );
}
