import type { ReactNode } from "react";
import { useCallback, useEffect } from "react";
import {
  FocusContext,
  useFocusable,
} from "@noriginmedia/norigin-spatial-navigation";
import clsx from "clsx";
import { useAtomValue } from "jotai";
import { useAtomCallback } from "jotai/utils";

import {
  type ActionDialogAction,
  type ActionsDialog as ActionsDialogType,
} from "@sunrise/dialogs";
import { openUrlFnAtom } from "@sunrise/platform";
import { useTranslatable } from "@sunrise/translator";
import { legacySocketAtom } from "@sunrise/yallo-websocket";

import { FocusButton } from "@/components";
import type { DialogOptions } from "@/features/dialogs/types";
import { pageIdAtom } from "@/features/monitoring/page-id.atom";

import * as styles from "./actions-dialog.css";

/**
 * Contents of the dialog when type: 'actions' is set in the dialogsAtom.
 *
 * It will show a list of actions the user can take and focus either on the first one or
 * on the one that requires initial focus from the atom.
 */
export function ActionsDialog({
  dialog,
  "data-testid": testId,
  doClose,
}: DialogOptions & { dialog: ActionsDialogType }): ReactNode {
  const actionIndex = dialog.actions.findIndex((v) => v.initialFocus);
  const t = useTranslatable();

  const { focusKey, focusSelf, ref, focused, hasFocusedChild } = useFocusable({
    isFocusBoundary: true,
    focusable: !!dialog,
    preferredChildFocusKey:
      actionIndex >= 0
        ? getChildKeyForAction(dialog.actions[actionIndex], actionIndex)
        : undefined,
  });

  // WEBSOCKET message:
  const websocket = useAtomValue(legacySocketAtom);
  const getPageId = useAtomCallback(
    useCallback((get) => {
      return get(pageIdAtom);
    }, []),
  );

  useEffect(() => {
    if (!dialog.technicalErrorName) return;

    const pageId = getPageId();
    if (websocket && pageId) {
      websocket.logError(dialog.technicalErrorName, pageId);
    }
  }, [dialog.id, dialog.technicalErrorName, getPageId, websocket]);

  // We need the dialog to be in there because we need to re-focus when the dialog contents change.
  useEffect(() => {
    if (!focused && !hasFocusedChild && dialog) {
      focusSelf();
    }
  }, [focusSelf, focused, hasFocusedChild, dialog]);

  const leftAlignment = dialog.textAlignment === "left";
  const urlOpenerFn = useAtomValue(openUrlFnAtom);

  return (
    <FocusContext.Provider value={focusKey}>
      <div
        ref={ref}
        className={clsx([
          styles.buttons,
          leftAlignment && styles.buttonsLeftAligned,
        ])}
        data-testid={`${testId}.actions`}
      >
        {"actions" in dialog &&
          dialog.actions.map((action, i) => {
            const key = getChildKeyForAction(action, i);

            return (
              <FocusButton
                key={key}
                block={!leftAlignment}
                className={styles.button}
                data-testid={`${testId}.button.${key}`}
                focusKey={key}
                text={t(action.label) ?? key ?? `button ${i}`}
                onEnterPress={() => {
                  if ("url" in action) {
                    urlOpenerFn(action.url);
                  } else {
                    void action.action();
                  }
                  if (!action.closeDisabled) {
                    doClose();
                  }
                }}
              />
            );
          })}
      </div>
    </FocusContext.Provider>
  );
}
function getChildKeyForAction(
  action: ActionDialogAction | undefined,
  index: number | undefined,
): string | undefined {
  if (!action || typeof index === "undefined" || index < 0) {
    return undefined;
  }

  return action.key ?? "action-" + index;
}
