import { getCurrentFocusKey } from "@noriginmedia/norigin-spatial-navigation";

import { useErrorDialog } from "@sunrise/error-dialog";

import { ErrorDialogDescription } from "./error-dialog-description";

export function ErrorDialog(): undefined {
  useErrorDialog({
    getFocusKey: getCurrentFocusKey,
    getConfirmationDescription: (dialog, eventId) => ({
      type: "component",
      component: (
        <ErrorDialogDescription
          description={dialog.description}
          eventId={eventId}
        />
      ),
    }),
    skipSentryCapture: !import.meta.env.VITE_SENTRY_DSN && import.meta.env.DEV,
  });
}
