import { atom } from "jotai";

/**
 * Mechanism to open a URL in a new tab on a specific platform.
 *
 * To be overriden whenever the platform does not implement window.open _blank.
 */
export const openUrlFnAtom = atom(() => {
  return (url: string) => {
    window.open(url, "_blank");
  };
});
