import { forwardRef, useCallback, useContext } from "react";

import { MouseNavigationContext } from "@sunrise/bigscreen";
import { type Nullable } from "@sunrise/utils";
import type { SimplifiedRecordingStatus } from "@sunrise/yallo-recordings";

import {
  ProgramSubtitle,
  ProgramTime,
  ProgramTitle,
} from "../box-text-elements/box-text";
import * as styles from "../program-box.css";
import { ProgramBoxWrapper } from "../program-box-wrapper";
import { ProgramCover } from "../program-cover/program-cover";
import type { BoxProps } from "../types";

export type ProgramBoxProps = CommonProps &
  BoxProps & {
    title: Nullable<string>;
    airTime: Nullable<string>;
    start: Nullable<string>;
    end: Nullable<string>;
    startDate?: Nullable<Date>;
    endDate?: Nullable<Date>;
    kind: "normal" | "large";
    subtitle?: Nullable<string>;
    active?: boolean;
    liveProgress?: Nullable<number>;
    replayProgress?: Nullable<number>;
    recordingState?: Nullable<SimplifiedRecordingStatus>;
    "data-position"?: number;
  };

export const ProgramBox = forwardRef<HTMLDivElement, ProgramBoxProps>(
  function ForwardRefProgramBox(
    {
      "data-testid": dataTestId = "program-box",
      "data-focused": dataFocused,
      "data-position": dataPosition,
      className,
      kind,
      airTime,
      start,
      startDate,
      end,
      endDate,
      subtitle,
      title,
      logoUrl,
      coverImageUrl,
      focused,
      liveProgress,
      recordingState,
      replayProgress,
      active,
      navId,
      children,
    },
    ref,
  ) {
    const mouseNavigation = useContext(MouseNavigationContext);
    const onClick = useCallback(() => {
      mouseNavigation.enterElement(navId);
    }, [navId, mouseNavigation]);
    const onMouseEnter = useCallback(() => {
      mouseNavigation.focusElement(navId);
    }, [navId, mouseNavigation]);

    return (
      <ProgramBoxWrapper
        ref={ref}
        className={className}
        data-focused={dataFocused}
        data-position={dataPosition}
        data-testid={dataTestId}
        endDate={endDate}
        focused={focused}
        kind={kind}
        startDate={startDate}
        onClick={onClick}
        onMouseEnter={onMouseEnter}
      >
        {children ? <div className={styles.lock}>{children}</div> : null}

        <ProgramTime
          airTime={airTime}
          className={styles.airTime}
          data-testid={`${dataTestId}.time`}
          end={end}
          start={start}
          spaceBetween
        />
        <ProgramCover
          coverImageUrl={coverImageUrl}
          data-testid={`${dataTestId}.cover`}
          focused={focused}
          kind={kind}
          liveProgress={liveProgress}
          logoUrl={logoUrl}
          recordingState={recordingState}
          replayProgress={replayProgress}
        />
        <div className={styles.text}>
          {title && (
            <ProgramTitle
              active={active}
              data-testid={`${dataTestId}.title`}
              title={title}
            />
          )}
          {subtitle && (
            <ProgramSubtitle
              data-testid={`${dataTestId}.subtitle`}
              subtitle={subtitle}
            />
          )}
        </div>
      </ProgramBoxWrapper>
    );
  },
);
