import { atom } from "jotai";
import { atomEffect } from "jotai-effect";

import { type MemoryUsageValue, readMemoryAtom } from "@sunrise/bigscreen";

import { MEMORY_UPDATE_INTERVAL } from "@/modules/memory/constants";

export const memoryUsageAtom = atom<MemoryUsageValue | null>(null);

export const memoryUsageEffect = atomEffect((get, set) => {
  function update(): void {
    const state = get(readMemoryAtom)?.() || null;
    if (!state) {
      // When we do not get state, it is disabled.
      clearInterval(intervalId);
      console.warn(
        "Not receiving memory updates, disabling memory monitoring.",
      );
    }
    set(memoryUsageAtom, state);
  }

  const intervalId = setInterval(update, MEMORY_UPDATE_INTERVAL);
  update();

  return () => {
    clearInterval(intervalId);
  };
});
