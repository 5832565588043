import { useCallback, useEffect } from "react";
import { useAtomValue, useSetAtom } from "jotai";

import { selectPlayerState } from "@sunrise/player";
import { useIdle } from "@sunrise/utils";

import { PLAYER_AUTO_HIDE_CONTROLS_TIMEOUT_IN_MS } from "../player-controls.constants";
import {
  actionPlayerControlsHide,
  lastDateOfIdleTimeoutAtom,
  playerControlsAtom,
  playerControlsVisibleAtom,
} from "../player-controls.store";

/**
 * A hook only to determine if the player controls are supposed to open and to auto-close the player controls.
 *
 * NOTE: This should probably be rebuilt as an atom effect and not a hook.
 **/
export function usePlayerControls(): {
  isVisible: boolean;
  doClose: () => void;
} {
  const dispatchPlayerControls = useSetAtom(playerControlsAtom);
  const playerState = useAtomValue(selectPlayerState);

  const idle = useIdle(PLAYER_AUTO_HIDE_CONTROLS_TIMEOUT_IN_MS);
  const isVisible = useAtomValue(playerControlsVisibleAtom);
  const setLastDateOfIdleTimeout = useSetAtom(lastDateOfIdleTimeoutAtom);

  const doClose = useCallback((): void => {
    dispatchPlayerControls(actionPlayerControlsHide());
  }, [dispatchPlayerControls]);

  useEffect(() => {
    if (idle && isVisible) {
      doClose(); // NOTE disable for working on seek-bar
      setLastDateOfIdleTimeout(new Date());
    }
  }, [isVisible, doClose, idle, playerState, setLastDateOfIdleTimeout]);

  return {
    isVisible,
    doClose,
  };
}
