import { createContext } from "react";

import type { NavigationId } from "..";

export type MouseNavigationContextValue = {
  focusElement: (navId: NavigationId) => void;
  enterElement: (navId: NavigationId) => void;
};

export const MouseNavigationContext =
  createContext<MouseNavigationContextValue>({
    focusElement: () => {
      // empty
    },
    enterElement: () => {
      // empty
    },
  });
