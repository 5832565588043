import type { ReactNode } from "react";
import { Suspense, useEffect, useMemo } from "react";
import {
  doesFocusableExist,
  FocusContext,
  setFocus,
  useFocusable,
} from "@noriginmedia/norigin-spatial-navigation";
import { useAtomValue, useSetAtom } from "jotai";

import type { MappedAsset } from "@sunrise/asset";
import type { RecordingGroupId } from "@sunrise/backend-types-core";
import { useKeyboardNavigation } from "@sunrise/bigscreen";
import { assetDetailsByIdAtom, getAssetTags } from "@sunrise/details";
import { selectIsDialogOpen } from "@sunrise/dialogs";
import { actionLocationNavigate, locationAtom } from "@sunrise/location";
import { useRecordSeries } from "@sunrise/yallo-recordings";
import { recordingItemsWithPagingForRecordingGroupIdAtom } from "@sunrise/yallo-recordings-series";

import { Header, PageSpinner } from "@/components";
import { globalFocusKey } from "@/config/focus-key";
import type { routeParam } from "@/config/route";
import { route } from "@/config/route";
import { DetailsInfo } from "@/features/details/details-info/details-info";
import { DetailsTags } from "@/features/details/details-tags/details-tags";
import { useMenu } from "@/features/menu/use-menu";
import { RecordingsSeriesList } from "@/features/recordings/recordings-series-list";
import { useRoutes } from "@/features/routing/use-routes";
import { canPullFocusAtom } from "@/modules/ui/can-pull-focus.atom";

import { main } from "./details-series.css";
import { DetailsSeriesActions } from "./details-series-actions";
import { DetailsSeriesNetworkIndicator } from "./details-series-network-indicator";

type DetailsSeriesPageProps = {
  params: typeof routeParam.detailsSeries;
};

const localWidgetFocusKey = {
  action: (idx: number) => `${globalFocusKey.activePage}.action.${idx}`,
  list: `${globalFocusKey.activePage}.details-series.list`,
};

export function DetailsSeries({ params }: DetailsSeriesPageProps): ReactNode {
  const { assetId, recordingGroupId } = params;
  const asset = useAtomValue(assetDetailsByIdAtom(assetId));

  // It's forbidden to show menu on this page
  useMenu({ hidden: true });

  const routes = useRoutes();

  const handleOnBack = (): void => {
    routes.back();
  };

  const isDialogOpen = useAtomValue(selectIsDialogOpen);
  useKeyboardNavigation({
    onBack: handleOnBack,
    isEnabled: !isDialogOpen,
  });

  const assetData = asset;
  if (!assetData || assetData.type !== "series") {
    return null;
  }

  return (
    <Suspense fallback={<PageSpinner data-testid="details-series" />}>
      <DisplayDetailsSeries
        asset={assetData}
        recordingGroupId={recordingGroupId}
      />
    </Suspense>
  );
}

const TEST_ID = "details-series-page";
type DisplayDetailsSeriesProps = {
  recordingGroupId: RecordingGroupId;
  asset: MappedAsset;
};

function DisplayDetailsSeries({
  asset,
  recordingGroupId,
}: DisplayDetailsSeriesProps): ReactNode {
  const tags = useMemo(
    () => getAssetTags(asset.genres, asset.productionYear),
    [asset.genres, asset.productionYear],
  );

  const { focusKey, ref, focused, focusSelf } = useFocusable({
    isFocusBoundary: true,
    autoRestoreFocus: true,
    focusKey: globalFocusKey.activePage,
    trackChildren: true,
    preferredChildFocusKey: localWidgetFocusKey.list,
  });

  const cancelButtonIndex = 3;
  const seasonButtonFocusKey = localWidgetFocusKey.action(0);
  const deleteButtonFocusKey = localWidgetFocusKey.action(4);
  const sortButtonFocusKey = localWidgetFocusKey.action(5);
  const cancelButtonFocusKey = localWidgetFocusKey.action(cancelButtonIndex);
  const dispatchLocation = useSetAtom(locationAtom);
  const onDeleteConfirm = (): void => {
    dispatchLocation(actionLocationNavigate(route.recordings.root()));
  };

  const canPullFocus = useAtomValue(canPullFocusAtom);
  useEffect(() => {
    if (canPullFocus) {
      focusSelf();
    }
  }, [focusSelf, canPullFocus]);

  const { onCancelRecordings, haveRecordingSchedules, onDeleteRecordings } =
    useRecordSeries({
      recordingGroupId,
      assetId: asset.id,
      deleteButtonFocusKey,
      onDeleteConfirm,
    });

  const { hasItems: hasRecordings } = useAtomValue(
    recordingItemsWithPagingForRecordingGroupIdAtom({
      recordingGroupId,
      seriesAssetId: asset.id,
    }),
  );

  const cancelRecordingsAndFocusList = (): void => {
    onCancelRecordings();
    setFocus(deleteButtonFocusKey);
  };
  const focusActions = (column: number): void => {
    const actionsLength = 4; // how many divisions we have in the actions list (0 based)
    const listLength = 3; // same for the list (0 based)
    const listPercent = column / listLength;
    let columnToFocus = Math.round(listPercent * actionsLength); // closest item in the actions based on the list index
    let step = 1;

    // if that index is not available, we will try to find the closest one
    if (!doesFocusableExist(localWidgetFocusKey.action(columnToFocus))) {
      while (step < actionsLength) {
        const next = columnToFocus + step;
        const previous = columnToFocus - step;
        if (doesFocusableExist(localWidgetFocusKey.action(next))) {
          columnToFocus = next;
          break;
        }

        if (doesFocusableExist(localWidgetFocusKey.action(previous))) {
          columnToFocus = previous;
          break;
        }

        step++;
      }
    }

    // special case where the cancel button is disabled, 'dosFocusableExist' doesn't consider this case
    if (columnToFocus === cancelButtonIndex && !haveRecordingSchedules) {
      setFocus(deleteButtonFocusKey);
    } else {
      setFocus(localWidgetFocusKey.action(columnToFocus));
    }
  };

  useEffect(() => {
    if (
      !focused ||
      ((hasRecordings || haveRecordingSchedules) &&
        !doesFocusableExist(localWidgetFocusKey.list))
    ) {
      focusSelf();
    }
  }, [focused, focusSelf, hasRecordings, haveRecordingSchedules]);

  return (
    <FocusContext.Provider value={focusKey}>
      <main ref={ref} className={main} data-testid={TEST_ID}>
        <Header
          left={
            <>
              <DetailsTags
                assetId={asset.id}
                data-testid={`${TEST_ID}.details-tags`}
                tags={tags}
              />{" "}
              <DetailsSeriesNetworkIndicator
                recordingGroupId={recordingGroupId}
                seriesAssetId={asset.id}
              />
            </>
          }
        />
        <DetailsInfo
          assetId={asset.id}
          data-testid={`${TEST_ID}.details-info`}
          description={asset.plot}
          title={asset.title}
        />
        <DetailsSeriesActions
          cancelButtonFocusKey={cancelButtonFocusKey}
          deleteButtonFocusKey={deleteButtonFocusKey}
          haveRecordingSchedules={haveRecordingSchedules}
          recordingGroupId={recordingGroupId}
          seasonButtonFocusKey={seasonButtonFocusKey}
          seriesAssetId={asset.id}
          sortButtonFocusKey={sortButtonFocusKey}
          onCancelRecordings={cancelRecordingsAndFocusList}
          onDeleteRecordings={onDeleteRecordings}
        />
        <RecordingsSeriesList
          focusKey={localWidgetFocusKey.list}
          recordingGroupId={recordingGroupId}
          seriesAssetId={asset.id}
          onExitUp={focusActions}
        />
      </main>
    </FocusContext.Provider>
  );
}
