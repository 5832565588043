import { useCallback, useMemo } from "react";
import { useAtomValue, useSetAtom } from "jotai";

import type {
  ChannelId,
  EPGEntryId,
  TimeISOString,
} from "@sunrise/backend-types-core";
import { nowAtom } from "@sunrise/time";
import { continueWatchingStatusByEpgIdAtom } from "@sunrise/yallo-continue-watching";
import { isWithinReplayWindow } from "@sunrise/yallo-replay";

import {
  actionPlayerManagerPlayRecording,
  actionPlayerManagerPlayReplay,
  playerManagerAtom,
} from "../player-manager.atom";

/**
 * A hook which will return if we can resume the current item or not and a function to resume it.
 * It needs the recordingId if it is available as well as a channelId to not overcomplicate the logic here.
 * They could be made optional in the future and load the data themselves as needed.
 * For now this hook is called only in usePlayProgram so we can just pass in the data from there.
 */
export function useResumeProgram({
  epgId,
  channelId,
  epgStartTime,
}: {
  epgId: EPGEntryId;
  channelId: ChannelId;
  epgStartTime: Date | TimeISOString;
}): { canResume: boolean; resume: () => void; isFullyWatched: boolean } {
  const dispatchPlayerManager = useSetAtom(playerManagerAtom);

  const status = useAtomValue(continueWatchingStatusByEpgIdAtom(epgId));
  const now = useAtomValue(nowAtom);

  const isFullyWatched = status ? status.isFullyWatched : true;
  const inWindowCheck =
    status?.type === "replay"
      ? isWithinReplayWindow(
          status.epgStartTime ? new Date(status.epgStartTime) : epgStartTime,
          now,
        ).isInReplayWindow
      : true;

  // When it is a replay status we need to check if it is still in the replay window.
  const canResume = !isFullyWatched && inWindowCheck;

  const resume = useCallback(() => {
    if (!canResume || !status) {
      return;
    }

    switch (status.type) {
      case "replay":
        dispatchPlayerManager(
          actionPlayerManagerPlayReplay(
            epgId,
            channelId,
            /**
             * We need to pass the timepoint at which we want to start the replay. Sometimes we'd like to start at the beginning of the program. Sometimes we want to start at the moment the user left off.
             * It's required so the player manager doesn't need to do an additional request to the backend to get the correct timepoint in case we start from the beginning.
             *
             * When we have a status it's possible it is fully watched. If so, the moment will be null. And in that case, we should start at the time the program started.
             * But it is possible that the epgStartTime is also null due to legacy not guaranteeing the presence of this without doing an additional request to load the epg data.
             * Therefore the last fallback is epgStartTime which ensures we always fall back to the start time of the program even if the continue watching API does not provide it.
             *
             * This can be cleaned up when legacy is removed. Then epgStartTime should always be present.
             */
            new Date(status.moment ?? status.epgStartTime ?? epgStartTime),
          ),
        );
        break;
      case "recording":
        dispatchPlayerManager(
          actionPlayerManagerPlayRecording(
            status.recordingId,
            channelId,
            status.playPositionSecond ?? undefined,
          ),
        );
        break;
    }
  }, [
    status,
    dispatchPlayerManager,
    epgId,
    channelId,
    canResume,
    epgStartTime,
  ]);

  return useMemo(
    () => ({
      canResume,
      resume,
      isFullyWatched: status ? status.isFullyWatched : false,
    }),
    [canResume, resume, status],
  );
}
