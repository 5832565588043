import { readTizenPlatformVersion } from "../tizen";

/**
 * Makes record filled with tags with device information
 */
export function makeMonitoringTags(): Record<string, string> {
  return {
    tizenPlatformVersion: readTizenPlatformVersion(),
  };
}
