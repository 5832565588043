import { atom } from "jotai";
import { atomEffect } from "jotai-effect";

import { SearchFilters } from "@sunrise/backend-ng-search";
import { selectIsDialogOpen } from "@sunrise/dialogs";
import { selectPlayerCurrentPlayRequest } from "@sunrise/player";
import type { PlayRequest } from "@sunrise/yallo-player-types";

import { searchFilterAtom } from "./ng-search.atom";
import { debouncedQueryAtoms } from "./search.debounce.atom";

const baseSearchIsOpenAtom = atom(false);

const previousPlayRequestAtom = atom<PlayRequest | null>(null);
const previousLocationAtom = atom<string | null>(null);

export const searchIsOpenAtom = atom(
  (get) => {
    get(closeEffect);
    get(locationChangeEffect);
    get(clearSearchModalEffect);
    get(searchKeydownEffect);
    get(closeOnModalEffect);
    return get(baseSearchIsOpenAtom);
  },
  (get, set, isOpen: boolean) => {
    set(previousPlayRequestAtom, get(selectPlayerCurrentPlayRequest) ?? null);
    set(previousLocationAtom, window.location.href);
    set(baseSearchIsOpenAtom, isOpen);
  },
);

/**
 * This effect closes the search modal when the playrequest changes compared to the one that was playing on open.
 */
const closeEffect = atomEffect((get, set) => {
  const isOpen = get(baseSearchIsOpenAtom);

  const playRequest = get(selectPlayerCurrentPlayRequest);
  const previousPlayRequest = get(previousPlayRequestAtom);
  if (
    !isOpen ||
    !previousPlayRequest ||
    !playRequest ||
    previousPlayRequest === playRequest
  ) {
    return;
  }

  set(baseSearchIsOpenAtom, false);
});

/**
 * This effect closes the search modal when a dialog is open.
 */
const closeOnModalEffect = atomEffect((get, set) => {
  const isOpen = get(baseSearchIsOpenAtom);
  if (!isOpen) return;

  const hasOpenDialog = get(selectIsDialogOpen);

  if (hasOpenDialog) {
    set(baseSearchIsOpenAtom, false);
  }
});

/**
 * This effect closes the search when the location changes.
 *
 * The modal is no longer relevant when the content beneath it changed.
 */
const locationChangeEffect = atomEffect((get, set) => {
  const open = get(baseSearchIsOpenAtom);
  if (!open) return;

  const handlePopState = () => {
    set(baseSearchIsOpenAtom, false);
  };

  window.addEventListener("popstate", handlePopState);
  window.addEventListener("pushState", handlePopState);
  window.addEventListener("replaceState", handlePopState);
  window.addEventListener("hashchange", handlePopState);

  return () => {
    window.removeEventListener("popstate", handlePopState);
    window.removeEventListener("pushState", handlePopState);
    window.removeEventListener("replaceState", handlePopState);
    window.removeEventListener("hashchange", handlePopState);
  };
});

/**
 * This effect clears the search modal when the search is closed.
 * But it will not clear the search modal if a dialog is open.
 * Because then we are assuming there's an upsell message shown because of an interaction with the search modal.
 *
 * And so on re-open of the search we want the search state to still be there.
 */
const clearSearchModalEffect = atomEffect((get, set) => {
  const isSearchOpen = get(baseSearchIsOpenAtom);

  if (isSearchOpen) {
    return;
  }

  const timeout = setTimeout(() => {
    const hasOpenDialog = get.peek(selectIsDialogOpen);
    if (hasOpenDialog) {
      return;
    }

    set(debouncedQueryAtoms.currentValueAtom, "");
    set(searchFilterAtom, SearchFilters.All);
  }, 500);

  return () => {
    clearTimeout(timeout);
  };
});

/**
 * This effect opens the search modal when the user presses "/".
 */
const searchKeydownEffect = atomEffect((get, set) => {
  const isOpen = get(baseSearchIsOpenAtom);

  // When the search is open, we don't want to open/close it again when the user presses "/"
  if (isOpen) return;

  const handler = (event: KeyboardEvent) => {
    if (event.ctrlKey || event.altKey || event.metaKey) {
      return;
    }

    if (event.key === "/") {
      set(baseSearchIsOpenAtom, true);
    }
  };

  document.addEventListener("keydown", handler);
  return () => {
    document.removeEventListener("keydown", handler);
  };
});
