import type {
  ContinueWatchingRecordingSchema,
  ContinueWatchingReplaySchema,
} from "@sunrise/backend-ng-continue-watching";
import type {
  AssetId,
  ChannelId,
  EPGEntryId,
  RecordingId,
} from "@sunrise/backend-types-core";
import type { Nullable } from "@sunrise/utils";

import type { RowRecommendationsData } from "../types.ng";

export function mapBackendContinueWatchingNg(
  continueWatchingEntry:
    | ContinueWatchingRecordingSchema
    | ContinueWatchingReplaySchema,
): Nullable<RowRecommendationsData> {
  if (
    continueWatchingEntry.type === "recording" &&
    "recording" in continueWatchingEntry
  ) {
    return {
      type: "recording",
      recordingId: continueWatchingEntry.recording.id as RecordingId,

      title: continueWatchingEntry.recording.asset.title,
      channelLogo: continueWatchingEntry.recording.channel_logo ?? "",
      channelName: continueWatchingEntry.recording.channel_name ?? "",
      coverImageUrl: continueWatchingEntry.recording.asset.poster_url,
      epgId: continueWatchingEntry.recording.epg_entry_id as EPGEntryId,
      epgStart: new Date(continueWatchingEntry.recording.epg_start),
      epgEnd: new Date(continueWatchingEntry.recording.epg_end),
      channelId: continueWatchingEntry.recording.channel_id as ChannelId,
      assetId: continueWatchingEntry.recording.asset.id as AssetId,
      subtitle: continueWatchingEntry.recording.asset.subtitle ?? "",
      seasonNumber: continueWatchingEntry.recording.asset.season_number,
      episodeNumber: continueWatchingEntry.recording.asset.episode_number,
      paddingEndMinutes: continueWatchingEntry.recording.padding_end_minutes,
      paddingStartMinutes:
        continueWatchingEntry.recording.padding_start_minutes,
    };
  } else if (
    continueWatchingEntry.type === "epg_entry" &&
    "epg_entry" in continueWatchingEntry
  ) {
    return {
      type: "epg_entry",
      epgId: continueWatchingEntry.epg_entry.id as EPGEntryId,
      title: continueWatchingEntry.epg_entry.asset.title,
      channelLogo: continueWatchingEntry.epg_entry.channel.logo,
      channelName: continueWatchingEntry.epg_entry.channel.name,
      coverImageUrl: continueWatchingEntry.epg_entry.asset.poster_url,
      subtitle: continueWatchingEntry.epg_entry.asset.subtitle,
      epgStart: continueWatchingEntry.epg_entry.start,
      epgEnd: continueWatchingEntry.epg_entry.end,
      channelId: continueWatchingEntry.epg_entry.channel.id as ChannelId,
      assetId: continueWatchingEntry.epg_entry.asset.id as AssetId,
      seasonNumber: continueWatchingEntry.epg_entry.asset.season_number,
      episodeNumber: continueWatchingEntry.epg_entry.asset.episode_number,
    };
  }

  return null;
}
