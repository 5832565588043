export async function loadImageAsBase64(url: string): Promise<string | null> {
  const blob = await (await fetch(url)).blob();

  // We store it as an imageblob since it renders faster.
  return new Promise((onSuccess, onError) => {
    try {
      const reader = new FileReader();
      reader.onload = function () {
        onSuccess(typeof this.result === "string" ? this.result : null);
      };
      reader.onerror = onError;
      reader.readAsDataURL(blob);
    } catch (e) {
      onError(e);
    }
  });
}
